import { createEntity, updateEntity } from "config/APIs/startup";
import { checkCompleteOrNot } from "helpers/utils/startup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateDraftField } from "redux/draft";

export default function Status() {
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const onSave = async () => {
    try {
      let { isDraft } = checkCompleteOrNot(details);
      let body = {
        ...details,
        imageId: details?.image?.id,
        founders: details?.founder ? [{ name: details?.founder }] : null,
        isDraft: isDraft,
      };
      if (details?.id) {
        let data = await updateEntity(details?.id, body);
      } else {
        let res = await createEntity(body);
        dispatch(updateDraftField({ field: "id", value: res?.data?.data?.id }));
      }
    } catch (e) {
      console.log("Error: ", e);
    }
    history.push("/home/drafts/chapterflow");
  };
  return (
    <div className="flex items-center justify-between">
      <p className="font-satoshi font-light text-xs text-neutral-500"></p>
      <button
        onClick={() => {
          onSave();
        }}
        className="font-satoshi font-extrabold text-xs text-primary-magenta-medium"
      >
        Save & Exit
      </button>
    </div>
  );
}
