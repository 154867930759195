import { getSusFlag } from "config/APIs/startup";

export const getSusCheck = async (type, field, value, alternateField) => {
  try {
    let response = await getSusFlag({ type, field, value });
    let res = response?.data;
    let val = {
      [field]: {
        value: value,
        susCheck: res?.susCheck,
      },
    };
    if (alternateField) {
      val[alternateField] = value;
    }

    return { val: val, message: res?.message };
  } catch (e) {
    console.log(e);
  }
};
