import moment from "moment";
import { useHistory } from "react-router-dom";

function Drafts({ list }) {
  const history = useHistory();
  const getPercentage = (item) => {
    let allKeys = Object.keys(item);
    let filledKeys = [];

    Object.keys(item)?.map((key) => {
      let v = item[key];
      if (v) filledKeys.push(key);
    });

    return parseInt((filledKeys?.length * 100) / allKeys?.length);
  };

  return (
    <div className="max-7xl mx-auto p-4 w-full flex flex-col space-y-10">
      <div className="flex mb-10 w-full h-min justify-between items-center gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="font-satoshi font-bold text-[28px] text-primary-magenta-medium">
            Story Drafts
          </h1>
          <h3 className="font-lato font-light text-sm text-neutral-800 tracking-[0.7px]">
            Page description here
          </h3>
        </div>
        <div></div>
        <img
          className="h-14 w-14"
          src="/assets/svg/pages/drafts/drafts_logo.svg"
        />
      </div>

      <div className="py-2 lg:px-2 flex flex-col space-y-8 pb-4">
        {list?.map((item) => {
          return (
            <div className="research-card relative lg:w-10/12 p-4 flex flex-col lg:flex-row items-stretch lg:space-x-8">
              <div className="flex flex-row items-stretch space-x-2">
                <img
                  src={item?.image?.url || "/assets/images/demo.png"}
                  alt=""
                  className="rounded-lg object-contain w-[64px] h-[64px] aspect-square"
                />
                <div
                  onClick={() => {
                    history.push(`/home/drafts/chapterflow/${item?.id}`);
                  }}
                  className="flex flex-col items-start"
                >
                  <h1 className="text-primary-neutral-800 font-poppins text-lg font-medium leading-7">
                    {item?.name}
                  </h1>
                  <h2 className="text-primary-neutral-800 font-inter text-xs font-light leading-7">
                    {item?.founders?.length > 0
                      ? item?.founders[0]?.name
                      : "No Founders"}{" "}
                    {item?.founders?.length > 1
                      ? `+${item?.founders?.length - 1}`
                      : ""}
                  </h2>
                </div>
              </div>

              <div className="flex flex-col space-y-6">
                <div className="flex flex-row items-center justify-between space-x-2">
                  <div className="w-11/12 bg-primary-neutral-100 rounded-full h-2">
                    <div
                      className="bg-primary-magenta-lighter rounded-full h-2"
                      style={{ width: `${getPercentage(item)}%` }}
                    />
                  </div>
                  <div className="p-2 flex flex-col items-center space-y-1">
                    <img
                      src="/assets/svg/diamond.png"
                      alt=""
                      className="w-[18px] h-[18px]"
                    />

                    <p className="text-primary-neutral-500 font-lato text-center text-xs font-semibold">
                      1/5
                    </p>
                  </div>
                </div>
                <div className="flex flex-row text-primary-neutral-400 font-inter text-2xs font-light items-center justify-between space-x-2">
                  <p>
                    Created On •{" "}
                    <span className="font-semibold">
                      {moment(item?.createdAt).format("ll")}
                    </span>{" "}
                  </p>
                  <p>
                    Last Edited •{" "}
                    <span className="font-semibold">
                      {moment(item?.updatedAt).startOf("day").fromNow()}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Drafts;
