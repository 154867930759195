import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function TopNav({ onBack }) {
  const history = useHistory;
  const draft = useSelector((state) => state?.draft);

  const getPercentage = () => {
    console.log(draft?.chapters[draft?.chapter]?.steps);
    let start = draft?.chapters[draft?.chapter]?.steps?.indexOf(draft?.step);
    let len = draft?.chapters[draft?.chapter]?.steps?.length;

    return parseInt(((start + 1) * 100) / len);
  };
  return (
    <>
      <div className="py-4 px-4 flex justify-between items-center border-b border-neutral-100">
        <div className="flex items-center">
          <img
            onClick={onBack}
            className="w-6 h-6 cursor-pointer"
            src={"/assets/svg/pages/drafts/icons/back.svg"}
          />
          <div
            onClick={() => history.push("/home/drafts/chapterflow")}
            className="flex items-center gap-2 ml-4 font-lato  text-neutral-800 text-sm"
          >
            <img
              key={draft?.details?.image?.id}
              src={draft?.details?.image?.url}
              className="w-8 h-8 bg-black rounded-lg object-cover"
            />
            <h2 className="font-semibold ">{draft?.details?.name}</h2>
            <h2 className="font-semibold">/</h2>
            <h2 className="text-zinc-800 text-sm font-light font-lato leading-[21px] tracking-tight">
              {draft?.chapters[draft?.chapter]?.name}
            </h2>
          </div>
        </div>
        <div className="font-satoshi font-medium text-sm text-neutral-800">
          {getPercentage()}%
        </div>
      </div>
    </>
  );
}

export default TopNav;
