import { useState, useEffect } from "react";

import TextInput from "../Common/text_input";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";

function Chapter2AFounderTop() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);

  const [founder, setFounder] = useState(
    details?.founder || details?.founder?.length > 0
      ? details?.founders?.name
      : ""
  );

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (!founder) setDisabled(true);
    else setDisabled(false);
  }, [founder]);

  return (
    <DraftLayout
      heading="Who’s at the top?"
      subheading="Identify the founder(s) of this startup"
      info="Information on how this is relevant"
      chapName="Founder"
      onNext={() => {
        dispatch(updateDraftField({ field: "founder", value: founder }));
        dispatch(nextStep());
      }}
      onPrevious={() => dispatch(prevStep())}
      bottomDisabled={disabled}
    >
      <TextInput
        value={founder}
        label="Founder Name"
        placeholder="John Doe"
        onChange={(e) => setFounder(e.target.value)}
      />
    </DraftLayout>
  );
}

export default Chapter2AFounderTop;
