import React from "react";
import { Redirect } from "react-router-dom";
import { checkAppAccess } from "helpers/utils/accessCheck";
import ResearchBank from "pages/ResearchBank";
import Dashboard from "pages/Dashboard";
import ChapterFlow from "pages/Drafts/ChapterFlow";
import AddDrafts from "pages/Drafts/AddDrafts";
import NexusBank from "pages/NexusBank";
import StartUp from "pages/NexusBank/Startup";

// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    tab: "Dashboard",
  },
  {
    path: "/home/bank",
    component: NexusBank,
    name: "Drafts",
    tab: "Nexus Bank",
  },
  {
    path: "/home/startup/:id",
    component: StartUp,
    name: "Drafts",
    tab: "Nexus Bank",
  },
  {
    path: "/home/researchBank",
    component: ResearchBank,
    name: "Research Bank",
    tab: "Research Bank",
  },
  {
    path: "/home/drafts/chapterflow/:id",
    component: ChapterFlow,
    name: "Drafts",
    tab: "Drafts",
  },
  {
    path: "/home/drafts/:type/:id",
    component: AddDrafts,
    name: "Drafts",
    tab: "Drafts",
  },
  {
    path: "/home/story/:type",
    component: ResearchBank,
    name: "Drafts",
    tab: "Drafts",
  },

  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          window.innerWidth < 1024 ? (
            <Redirect to="/dashboard" />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
