import { configureStore } from "@reduxjs/toolkit";

import toasterReducer from "./toaster";
import userReducer from "./user";
import startupReducer from "./startup";
import draftReducer from "./draft";

const store = configureStore({
  reducer: {
    toaster: toasterReducer,
    user: userReducer,
    startup: startupReducer,
    draft: draftReducer,
  },
});

export default store;
