import React from "react";

function InsightsProgress({ length, completed }) {
  return (
    <div className="items-stretch flex gap-2 mt-4 px-5 self-end">
      {[1, 2, 3, 4, 5]?.map((i) => {
        if (i <= length) {
          if (i <= completed)
            return (
              <div className="items-center bg-neutral-50 flex aspect-square flex-col justify-center h-8 flex-1 px-1 rounded-[128px]">
                <img
                  loading="lazy"
                  src="/assets/svg/pages/drafts/activepoint.svg"
                  className="aspect-square object-contain object-center w-full overflow-hidden"
                />
              </div>
            );
          else
            return (
              <div className="items-center bg-neutral-50 flex aspect-square flex-col justify-center h-8 flex-1 px-1 rounded-[128px]">
                <img
                  loading="lazy"
                  src="/assets/svg/pages/drafts/disablepoint.svg"
                  className="aspect-square object-contain object-center w-full overflow-hidden"
                />
              </div>
            );
        }
      })}
    </div>
  );
}

export default InsightsProgress;
