import { createSlice } from "@reduxjs/toolkit";
import { getAllEntity } from "config/APIs/startup";

const initialState = {
  list: [],
  nexus: [],
  story: [],
  draft: [],
  fetching: true,
};

// Storing User details and Token
export const startups = createSlice({
  name: "startup",
  initialState,
  reducers: {
    updateList: (state, action) => {
      if (action.payload.list) {
        let admin = JSON.parse(localStorage.getItem("admin"));
        let list = action.payload.list;
        state.list = list;
        state.nexus = list.filter((startup) => {
          return startup?.researcherId === null;
        });
        state.draft = list?.filter(
          (i) =>
            i?.isDraft &&
            (i?.creatorId == admin?.id || i?.researcherId == admin?.id)
        );
        state.story = list?.filter(
          (i) =>
            !i?.isDraft &&
            (i?.creatorId == admin?.id || i?.researcherId == admin?.id)
        );
      }
      state.fetching = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = startups.actions;

export default startups.reducer;

export function fetchAllStartups() {
  return async (dispatch) => {
    try {
      const response = await getAllEntity();

      if (response.status === 200) {
        let data = response.data.data?.response;

        dispatch(
          updateList({
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("project fetching error", err);
    }
  };
}
