import { instance } from "config";
import { getHeader, getMultipartHeader } from "config";

export const uploadFile = ({ file }) => {
  const formData = new FormData();
  formData.append("file", file);
  return instance.post(`/files/upload`, formData, getMultipartHeader());
};

export const deleteFile = ({ id }) =>
  instance.delete(`/files/${id}`, getHeader());
