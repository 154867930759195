import { useState } from "react";
import DraftLayout from "../Common/draftLayout";
import TextAreaInput from "../Common/textarea_input";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";

function Chapter3CVision() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [vision, setVision] = useState(details?.vision);

  return (
    <DraftLayout
      heading="The Vision"
      subheading="State the company’s Vison Statement"
      info="Information on where to find this"
      chapName="Company"
      onNext={() => {
        dispatch(updateDraftField({ field: "vision", value: vision }));
        dispatch(nextStep());
      }}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={false}
    >
      <main className="my-10 flex flex-col gap-10">
        <TextAreaInput
          value={vision}
          label="Vision Statement"
          placeholder="Company vision statement comes here"
          onChange={(e) => setVision(e.target.value)}
        />
      </main>
    </DraftLayout>
  );
}

export default Chapter3CVision;
