import React from "react";

const idea = "/assets/svg/pages/Idea.svg";

import { ImQuotesRight } from "react-icons/im";
import { MdInsights } from "react-icons/md";

const InsightsCard = ({ placeholder, data, setData, field }) => {
  return (
    <div className="flex max-md:flex-col items-center gap-10 sm:gap-20">
      <div className="flex flex-[1_0_0] w-full flex-col flex-start gap-10">
        <div className="flex flex-col items-center flex-start gap-4">
          <div className="flex items-center gap-4 self-stretch">
            <h1 className="flex flex-1 text-sm font-inter font-semibold text-neutral-500">
              Cite Your Sources
            </h1>
            <div className="flex w-5 h-5 flex-col items-center justify-center rounded-full border border-neutral-300">
              <h1 className="text-neutral-400 font-inter text-xs font-semibold">
                ?
              </h1>
            </div>
          </div>
          <input
            value={data && data[field] ? data[field]?.source : ""}
            onChange={(e) => {
              setData({
                ...data,
                [field]: {
                  ...data[field],
                  source: e.target.value,
                },
              });
            }}
            placeholder="Add source link here"
            className="font-lato font-semibold focus:ring-0 focus:outline-none text-lg w-full border-0 border-b-2 border-neutral-200 leading-[27px] tracking-tight placeholder:text-neutral-300"
          />
        </div>
        <div className="flex flex-col items-center flex-start gap-4">
          <div className="flex items-center gap-4 self-stretch">
            <h1 className="flex text-sm font-inter font-semibold text-neutral-500">
              Researcher Insight
            </h1>
            <div className="flex flex-1 gap-1 items-center">
              <MdInsights size="24px" color="#8DA1FC" />
              <h1 className="font-inter text-sm font-semibold text-[#8DA1FC]">
                +1
              </h1>
            </div>
            <div className="flex w-5 h-5 flex-col items-center justify-center rounded-full border border-neutral-300">
              <h1 className="text-neutral-400 font-inter text-xs font-semibold">
                ?
              </h1>
            </div>
          </div>
          <input
            value={data && data[field] ? data[field]?.insights : ""}
            onChange={(e) => {
              setData({
                ...data,
                [field]: {
                  ...data[field],
                  insights: e.target.value,
                },
              });
            }}
            placeholder={placeholder}
            className="h-[120px] text-black text-sm font-light font-lato leading-[150%] tracking-tightfont-lato w-full p-4 border-[1px] border-neutral-300 bg-gray-50 rounded outline-none placeholder:text-neutral-300 placeholder:font-light focus:ring-0 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};

export default InsightsCard;
