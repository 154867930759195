import BottomNav from "./bottom_nav";
import TopNav from "./top_nav";
import Status from "./status";
import TitleBar from "./titlebar";

function DraftLayout({
  children,
  heading,
  subheading,
  info,
  onPrevious,
  onNext,
  bottomDisabled,
}) {
  return (
    <div className="sticky-thc flex flex-col bg-white h-full lg:h-auto">
      <TopNav onBack={onPrevious} />

      {/* Main Body --------- */}
      <main className="pb-10 p-4 w-full h-[75vh] overflow-y-scroll space-y-3">
        <Status />
        <TitleBar heading={heading} subheading={subheading} info={info} />
        {children}
      </main>
      <BottomNav
        disabled={bottomDisabled}
        onPrevious={onPrevious}
        onNext={onNext}
        padding
      />
    </div>
  );
}

export default DraftLayout;
