import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Drafts from "components/ResearchBank/Drafts";
import Completed from "components/ResearchBank/Completed";
import { deleteEntity } from "config/APIs/startup";
import { useSelector } from "react-redux";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import { useEffect } from "react";

const ResearchBank = () => {
  const history = useHistory();
  const { type } = useParams();
  const list = useSelector((state) => state?.startup);
  const [selectedTab, setSelectedTab] = useState("draft");
  const [selectedToDelete, setSelectedToDelete] = useState();

  const deleteOne = async (id) => {
    try {
      let res = await deleteEntity(id);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setSelectedTab(type);
  }, [type]);

  const onEdit = async (id) => {
    history.push(`/home/sus/${id}`);
  };

  return (
    <>
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => {
          setSelectedToDelete();
        }}
        onAccept={() => {
          deleteOne(selectedToDelete?.id);
          setSelectedToDelete();
        }}
        text={
          <>
            Are you sure you want to delete the entry {selectedToDelete?.name}?
          </>
        }
      />

      <div className="sticky-thc h-[90vh] lg:h-auto">
        <div className="flex flex-col space-y-5 bg-white relative">
          {selectedTab == "draft" && (
            <Drafts
              list={list?.draft}
              onDelete={(val) => {
                setSelectedToDelete(val);
              }}
              onEdit={(val) => {
                onEdit(val);
              }}
            />
          )}
          {selectedTab == "completed" && (
            <Completed
              list={list?.story}
              onEdit={(val) => {
                onEdit(val);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ResearchBank;
