import BottomNav from "../Common/bottom_nav";
import { useState, useEffect } from "react";
import { FilePicker } from "react-file-picker";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import { deleteFile, uploadFile } from "config/APIs/files";
import { nextStep, setForm, updateDraftField } from "redux/draft";
import { useHistory } from "react-router-dom";

function Chapter1ACoverStartup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [name, setName] = useState(details?.name);
  const [file, setFile] = useState({
    image: details?.image,
    imageId: details?.image?.id,
  });
  const [uploading, setUploading] = useState(false);
  const [error, SetError] = useState({ name: undefined, image: undefined });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (name === undefined || name?.length === 0) setDisabled(true);
    else setDisabled(false);
  }, [name, file]);

  useEffect(() => {
    setName(details?.name);
    setFile({
      image: details?.image,
      imageId: details?.image?.id,
    });
  }, [details]);

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const handleFilePicker = async (pickedFile) => {
    if (pickedFile.size > 10485760) {
      handleError("File size cannot exceed 10MB");
      return;
    }
    console.log(pickedFile);
    setUploading(true);
    try {
      const response = await uploadFile({ file: pickedFile });

      const file = response.data.data;
      console.log(file);
      setFile({ image: file, imageId: file?.id });
      dispatch(updateDraftField({ field: "image", value: file }));
    } catch (err) {
      console.log("File Upload error", err);
      const message = `File Upload Error!`;
      handleError(message);
    }
    setUploading(false);
  };

  const handleDelete = async () => {
    await deleteFile({ id: file?.imageId });
    setFile({ image: null, imageId: null });
    dispatch(updateDraftField({ field: "image", value: null }));
  };
  const handleError = (err) => {
    dispatch(showToast({ message: err, type: "error" }));
  };

  const onNext = () => {
    if (name === undefined || name?.length === 0) {
      SetError({ ...error, name: "* name cannot be empty" });
    } else {
      dispatch(updateDraftField({ field: "name", value: name }));
      dispatch(nextStep());
    }
  };
  const handleFilePickerError = (err) => {
    console.log("File Picker Error", err);
    const message = `Please select an appropriate image file!`;
    handleError(message);
  };

  return (
    <div className="sticky-thc flex flex-col bg-white justify-between h-full lg:h-auto px-4 pt-4">
      <div>
        <img
          onClick={() => {
            history.goBack();
            dispatch(setForm({ value: {} }));
          }}
          className="w-6 h-6 cursor-pointer"
          src="/assets/svg/pages/drafts/icons/back.svg"
        />

        <div className="flex w-full justify-between items-center my-4">
          <h1 className="title-gradient font-satoshi font-bold text-primary-magenta-medium text-[28px] leading-[42px]">
            Seek Your First <br /> Startup
          </h1>

          <img
            className="w-[72px] h-[72px]"
            src="/assets/svg/pages/drafts/drafts_rocket.svg"
          ></img>
        </div>
        <h2 className="font-satoshi font-normal text-xl leading-[42px]">
          Add Startup name and logo to start
        </h2>

        {/* Upload Form  */}
        <div className="my-8 py-10 px-2 w-full h-max">
          <div className="flex gap-4 justify-start items-center w-full">
            {file?.image?.url ? (
              <img
                src={file?.image?.url}
                alt=""
                className="w-[100px] h-[100px] object-cover"
              />
            ) : (
              <img src="/assets/svg/pages/drafts/icons/add_logo.svg" />
            )}{" "}
            {uploading ? (
              <div className="flex gap-2 p-3 h-10 w-10 rounded-full animate-spin border border-t-0  border-primary-magenta-medium font-lato font-bold leading-7"></div>
            ) : file?.image?.url ? (
              <p
                onClick={() => {
                  handleDelete();
                }}
                className="font-inter text-primary-indigo-500 text-sm font-semibold underline underline-offset-2 cursor-pointer"
              >
                Remove Logo
              </p>
            ) : (
              <FilePicker
                extensions={["png", "jpeg", "jpg", "svg"]}
                onChange={handleFilePicker}
                onError={handleFilePickerError}
                maxSize={6000}
              >
                <div className="flex gap-2 p-3 text-primary-magenta-medium font-lato font-bold leading-7">
                  <img src="/assets/svg/pages/drafts/icons/upload.svg" />
                  Upload an Image
                </div>
              </FilePicker>
            )}
          </div>
          {error.img ? (
            <p className="text-red-500 font-lato font-semibold text-xs my-2">
              {error.img}
            </p>
          ) : (
            <></>
          )}

          <input
            value={name}
            onChange={onNameChange}
            type="text"
            placeholder="Add Startup Name Here"
            className="font-lato font-semibold text-xl mt-12 w-full border-0 border-b-2 border-neutral-200 placeholder:text-neutral-300 focus:ring-0"
          />
          {error.name ? (
            <p className="text-red-500 font-lato font-semibold text-xs my-2">
              {error.name}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>

      <BottomNav
        onPrevious={() => history.goBack()}
        onNext={onNext}
        disabled={disabled}
      />
    </div>
  );
}

export default Chapter1ACoverStartup;
