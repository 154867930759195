function BottomNav({ onPrevious, onNext, disabled = true }) {
  return (
    <div className="p-2 bg-white flex gap-10 justify-between fixed bottom-0 right-0 left-0">
      <button onClick={onPrevious} className="p-[18px]">
        Previous
      </button>
      <button
        disabled={disabled}
        onClick={onNext}
        className={`${
          disabled ? "bg-neutral-300 text-neutral-50" : "continue-button"
        } p-[18px] w-[150px] text-neutral-50 font-lato text-[16px] rounded-[18px]`}
      >
        Continue
      </button>
    </div>
  );
}

export default BottomNav;
