import { useState } from "react";

import DraftLayout from "../Common/draftLayout";
import TextAreaInput from "../Common/textarea_input";

import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";

function Chapter3DIdea() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [idea, setIdea] = useState(details?.description);

  return (
    <DraftLayout
      heading="The Idea"
      subheading="Give a brief description of what the company does."
      info="Information on where to find this"
      chapName="Company"
      onNext={() => {
        dispatch(updateDraftField({ field: "description", value: idea }));
        dispatch(nextStep());
      }}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={false}
    >
      <main className="my-10 flex flex-col gap-10">
        <TextAreaInput
          value={idea}
          label="Company Description"
          placeholder="Start typing...."
          onChange={(e) => setIdea(e.target.value)}
        />
      </main>
    </DraftLayout>
  );
}

export default Chapter3DIdea;
