import { useState, useEffect } from "react";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft } from "redux/draft";
import RadioCard from "components/SusForm/Common/SusCheckRadioCard";
import { showToast } from "redux/toaster";
import { getSusCheck } from "helpers/utils/startup/susCheck";
import InsightsCard from "components/SusForm/Common/InsightsCard";

function Chapter4DRev() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [data, setData] = useState({
    revenue: details?.revenue,
    currentRevenue: details?.currentRevenue,
  });
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data?.currentRevenue === undefined) setDisabled(true);
    else setDisabled(false);
  }, [data]);

  const onNext = () => {
    if (data?.currentRevenue === undefined) {
      setError({ ...error, workType: "* select a work type" });
    } else {
      dispatch(updateDraft({ value: data }));
      dispatch(nextStep());
    }
  };

  const doSusCheck = async (type, field, value, alternateField) => {
    try {
      let { val, message } = await getSusCheck(
        type,
        field,
        value,
        alternateField
      );
      let temp = { ...data };
      temp = { ...temp, ...val };
      setData(temp);
      dispatch(showToast({ message: message }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DraftLayout
      heading="Revenue"
      subheading="Discover the total revenue of the company till date"
      info="Information on how this is relevant"
      chapName="Viablity"
      onNext={onNext}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <main className="my-10 flex flex-col gap-10">
        <RadioCard
          list={[
            {
              label: "₹ 0-5 Cr.",
              value: "0-5 Cr.",
              flag: "green",
            },
            {
              label: "₹ 6-10 Cr.",
              value: "6-10 Cr.",
              flag: "white",
            },
            {
              label: "+ ₹ 10 Cr.",
              value: "10+ Cr.",
              flag: "red",
            },
          ]}
          data={data}
          field="revenue"
          onCheck={(val) => {
            doSusCheck("startup", "revenue", val, "currentRevenue");
          }}
        />

        {data?.revenue && (
          <InsightsCard
            data={data}
            field="revenue"
            setData={(val) => {
              setData(val);
            }}
            placeholder="eg., the current status of their startups, their names and the links to each of them"
            ideaText="Having little or no experience in building a company means that the founder could use some  help and guidance in building their startup"
          />
        )}
      </main>
    </DraftLayout>
  );
}

export default Chapter4DRev;
