import { useState } from "react";
import TextInput from "../Common/text_input";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft, updateDraftField } from "redux/draft";
import CheckBoxInput from "../Common/checkbox_input";

function Chapter5BSocial() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);

  const [website, setWebsite] = useState(
    details?.socialsPresence?.instagram?.name
  );
  const [number, setNumber] = useState(
    details?.socialsPresence?.instagram?.number
  );

  return (
    <DraftLayout
      heading="Social Presence"
      subheading="Assess the company’s Instagram footprint"
      info="Information on where to find this"
      chapName="Digital Footprint"
      onNext={() => {
        dispatch(
          updateDraft({
            value: {
              socialsPresence: {
                ...details?.socialsPresence,
                instagram: { name: website, number: number },
              },
            },
          })
        );
        dispatch(updateDraftField({ field: "instagram", value: website }));
        dispatch(nextStep());
      }}
      onPrevious={() => dispatch(prevStep())}
      bottomDisabled={false}
    >
      <main className="my-10 flex flex-col gap-10 items-center w-full">
        <TextInput
          value={website}
          onChange={(e) => {
            setWebsite(e.target.value);
          }}
          label="Instagram Address"
          placeholder="Instagram address here"
        />
        <TextInput
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          label="No. of Followers on Instagram"
          placeholder="100"
        />
        <h3 className="font-lato text-2xl font-light">or</h3>
        <CheckBoxInput
          text="Company is not on Instagram"
          onChange={(e) => {}}
        />
      </main>
    </DraftLayout>
  );
}

export default Chapter5BSocial;
