import { useState, useEffect } from "react";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft } from "redux/draft";
import RadioCard from "components/SusForm/Common/SusCheckRadioCard";
import { showToast } from "redux/toaster";
import { getSusCheck } from "helpers/utils/startup/susCheck";
import InsightsCard from "components/SusForm/Common/InsightsCard";

function Chapter4BFunding() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [data, setData] = useState({
    fundingRounds: details?.fundingRounds,
    funding: details?.funding,
  });
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data?.funding === undefined) setDisabled(true);
    else setDisabled(false);
  }, [data]);

  const onNext = () => {
    if (data?.funding === undefined) {
      setError({ ...error, funding: "* select a funding type" });
    } else {
      dispatch(updateDraft({ value: data }));
      dispatch(nextStep());
    }
  };
  const doSusCheck = async (type, field, value, alternateField) => {
    try {
      let { val, message } = await getSusCheck(
        type,
        field,
        value,
        alternateField
      );
      let temp = { ...data };
      temp = { ...temp, ...val };
      setData(temp);
      dispatch(showToast({ message: message }));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DraftLayout
      heading="Funding"
      subheading="Identify the funding status of the company"
      info="Information on how this is relevant"
      chapName="Viablity"
      onNext={onNext}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <main className="my-10 flex flex-col gap-10">
        <RadioCard
          list={[
            {
              label: "Bootstrapped/Pre-Seed",
              value: "Bootstrapped/Pre-Seed",
              flag: "green",
            },
            {
              label: "Seed Funding",
              value: "Seed Funding",
              flag: "white",
            },
            {
              label: "Series A,B,C",
              value: "Series A,B,C",
              flag: "red",
            },
          ]}
          data={data}
          field="fundingRounds"
          onCheck={(val) => {
            doSusCheck("startup", "fundingRounds", val, "funding");
          }}
        />

        {data?.fundingRounds && (
          <InsightsCard
            data={data}
            field="fundingRounds"
            setData={(val) => {
              setData(val);
            }}
            placeholder="eg., pointing out if the founder has any experience in the industry they are building their startup or any other experience that might be relevant to their current venture"
            ideaText="Having some work experience is indicative of industry knowledge ......... lorem ipsum some copy here"
          />
        )}
      </main>
    </DraftLayout>
  );
}

export default Chapter4BFunding;
