import { useState } from "react";
import TextInput from "../Common/text_input";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";
import CheckBoxInput from "../Common/checkbox_input";

function Chapter5AWebsite() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [website, setWebsite] = useState(details?.website);
  const [opt, setOpt] = useState(details?.website ? false : true);

  return (
    <DraftLayout
      heading="Web Representation"
      subheading="Discover the company’s website"
      info="Information on where to find this"
      chapName="Digital Footprint"
      onNext={() => {
        dispatch(updateDraftField({ field: "website", value: website }));
        dispatch(nextStep());
      }}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={false}
    >
      <main className="my-10 flex flex-col gap-10 items-center w-full">
        <TextInput
          value={website}
          onChange={(e) => {
            setWebsite(e.target.value);
          }}
          label="Website Link"
          placeholder="paste website link here"
        />
        <h3 className="font-lato text-2xl font-light">or</h3>
        <CheckBoxInput
          text="Company does not have a website"
          onChange={(e) => {
            setOpt(e.target.checked);
          }}
        />
      </main>
    </DraftLayout>
  );
}

export default Chapter5AWebsite;
