import ChapterCongrats from "../../../components/SusForm/Chapters/chap_congrats";

import Chapter1ACoverStartup from "../../../components/SusForm/Chapters/chap1ACover_startup";
import Chapter1BCoverStartupSector from "../../../components/SusForm/Chapters/chap1BCover_startupSector";

import Chapter2AStartupFounder from "../../../components/SusForm/Chapters/chap2AFounder_founders";

import Chapter3AWorkType from "../../../components/SusForm/Chapters/chap3ACompany_workType";
import Chapter3BMission from "../../../components/SusForm/Chapters/chap3BCompany_mission";
import Chapter3CVision from "../../../components/SusForm/Chapters/chap3CCompany_vision";
import Chapter3DIdea from "../../../components/SusForm/Chapters/chap3DCompany_idea";

import Chapter4AOperation from "../../../components/SusForm/Chapters/chap4AViabilty_operation";
import Chapter4BFunding from "../../../components/SusForm/Chapters/chap4BViabilty_funding";
import Chapter4CEmp from "../../../components/SusForm/Chapters/chap4CViabilty_emp";
import Chapter4DRev from "../../../components/SusForm/Chapters/chap4DViabilty_rev";

import Chapter5AWebsite from "../../../components/SusForm/Chapters/chap5ADigital_website";
import Chapter5BSocial from "../../../components/SusForm/Chapters/chap5BDigital_social";
import Chapter5CLinkedin from "../../../components/SusForm/Chapters/chap5CDigital_linkedin";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getOneEntity } from "config/APIs/startup";
import { setForm } from "redux/draft";

const AddDrafts = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const step = useSelector((state) => state?.draft?.step);

  const getComponent = () => {
    switch (step) {
      // Chapter 1
      case 1:
        return <Chapter1ACoverStartup />;
      case 2:
        return <Chapter1BCoverStartupSector />;
      case 3:
        return <ChapterCongrats />;

      // Chapter 2
      case 4:
        return <Chapter2AStartupFounder />;
      case 5:
        return <ChapterCongrats />;

      // Chapter 3.
      case 6:
        return <Chapter3DIdea />;
      case 7:
        return <Chapter3AWorkType />;
      case 8:
        return <Chapter3BMission />;
      case 9:
        return <Chapter3CVision />;
      case 10:
        return <ChapterCongrats />;

      // Chapter 4
      case 11:
        return <Chapter4AOperation />;
      case 12:
        return <Chapter4BFunding />;
      case 13:
        return <Chapter4CEmp />;
      case 14:
        return <Chapter4DRev />;
      case 15:
        return <ChapterCongrats />;

      // Chapter 5
      case 16:
        return <Chapter5AWebsite />;
      case 17:
        return <Chapter5BSocial />;
      case 18:
        return <Chapter5CLinkedin />;

      default:
        return <ChapterCongrats end />;
    }
  };

  useEffect(() => {
    if (type == "edit" && id !== 0) {
      getData();
    }
  }, [id]);

  const getData = async () => {
    try {
      let res = await getOneEntity(id);
      dispatch(setForm({ value: res?.data?.data }));
    } catch (e) {
      console.log(e);
    }
  };

  return <>{getComponent()}</>;
};

export default AddDrafts;
