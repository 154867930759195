import ChapterCircle from "./ChapterCircle";
import InsightsProgress from "./InsightsProgress";

export default function Chap3({
  lock,
  progress,
  onNavigate,
  insights,
  completedInsights,
}) {
  if (lock) {
    return (
      <div className="flex w-full justify-start items-center px-5">
        <img
          className="h-[114px] w-[114px]"
          src="/assets/svg/pages/drafts/chaplock.svg"
        />
        <div className="flex gap-4 px-4">
          <div className="flex flex-col font-lato text-neutral-500">
            <h1 className="font-semibold">Chapter 3</h1>
            <h2 className="text-xs">The Company</h2>
          </div>
          <img src="/assets/svg/pages/drafts/chap/chap_lock3.svg" />
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={onNavigate}
      className="flex gap-4 w-full justify-start items-center px-5"
    >
      <ChapterCircle
        progress={progress}
        svg={
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.9791 3.69053C11.9097 3.36971 10.8335 4.17046 10.8335 5.28689V36.6669H16.6668V30.8335C16.6668 29.913 17.413 29.1669 18.3335 29.1669H21.6668C22.5873 29.1669 23.3335 29.913 23.3335 30.8335V36.6669H29.1668V9.78689C29.1668 9.05088 28.684 8.40201 27.979 8.19053L12.9791 3.69053ZM16.6668 13.3335C16.2066 13.3335 15.8335 13.7066 15.8335 14.1669V15.8335C15.8335 16.2938 16.2066 16.6669 16.6668 16.6669H18.3335C18.7937 16.6669 19.1668 16.2938 19.1668 15.8335V14.1669C19.1668 13.7066 18.7937 13.3335 18.3335 13.3335H16.6668ZM15.8335 19.1669C15.8335 18.7067 16.2066 18.3335 16.6668 18.3335H18.3335C18.7937 18.3335 19.1668 18.7067 19.1668 19.1669V20.8335C19.1668 21.2937 18.7937 21.6669 18.3335 21.6669H16.6668C16.2066 21.6669 15.8335 21.2937 15.8335 20.8335V19.1669ZM16.6668 23.3335C16.2066 23.3335 15.8335 23.7067 15.8335 24.1669V25.8335C15.8335 26.2937 16.2066 26.6669 16.6668 26.6669H18.3335C18.7937 26.6669 19.1668 26.2937 19.1668 25.8335V24.1669C19.1668 23.7067 18.7937 23.3335 18.3335 23.3335H16.6668ZM20.8335 14.1669C20.8335 13.7066 21.2067 13.3335 21.6668 13.3335H23.3335C23.7937 13.3335 24.1668 13.7066 24.1668 14.1669V15.8335C24.1668 16.2938 23.7937 16.6669 23.3335 16.6669H21.6668C21.2067 16.6669 20.8335 16.2938 20.8335 15.8335V14.1669ZM21.6668 18.3335C21.2067 18.3335 20.8335 18.7067 20.8335 19.1669V20.8335C20.8335 21.2937 21.2067 21.6669 21.6668 21.6669H23.3335C23.7937 21.6669 24.1668 21.2937 24.1668 20.8335V19.1669C24.1668 18.7067 23.7937 18.3335 23.3335 18.3335H21.6668ZM20.8335 24.1669C20.8335 23.7067 21.2067 23.3335 21.6668 23.3335H23.3335C23.7937 23.3335 24.1668 23.7067 24.1668 24.1669V25.8335C24.1668 26.2937 23.7937 26.6669 23.3335 26.6669H21.6668C21.2067 26.6669 20.8335 26.2937 20.8335 25.8335V24.1669Z"
                fill="white"
              />
              <path
                d="M9.16683 36.6673V8.33398H6.66683C5.74636 8.33398 5.00016 9.08018 5.00016 10.0007V35.0007H4.16683C3.7066 35.0007 3.3335 35.3738 3.3335 35.834C3.3335 36.2942 3.7066 36.6673 4.16683 36.6673H9.16683Z"
                fill="white"
              />
              <path
                d="M35.8335 36.666H30.8335V14.6348L34.1603 16.5359C34.6797 16.8327 35.0002 17.3848 35.0002 17.983V34.9993H35.8335C36.2937 34.9993 36.6668 35.3725 36.6668 35.8327C36.6668 36.2928 36.2937 36.666 35.8335 36.666Z"
                fill="white"
              />
              <path
                d="M21.6668 36.6673V30.834H18.3335V36.6673H21.6668Z"
                fill="white"
              />
            </svg>
          </>
        }
      />
      <div className="justify-center items-stretch flex flex-col">
        <div className="justify-between items-stretch flex gap-4 px-5">
          <div className="justify-center flex grow basis-[0%] flex-col items-start">
            <div className="font-lato text-primary-magenta-medium text-xl font-semibold leading-8 tracking-wider whitespace-nowrap">
              Chapter 3
            </div>
            <div className="text-zinc-800 text-base font-semibold leading-6 tracking-wider self-stretch whitespace-nowrap">
              The Company
            </div>
          </div>
          <div className="font-lato text-neutral-500 text-base font-light leading-6 tracking-wider self-center my-auto">
            ({progress}%)
          </div>
        </div>
        <InsightsProgress length={insights} completed={completedInsights} />
      </div>
    </div>
  );
}
