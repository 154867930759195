import { useState, useEffect } from "react";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft } from "redux/draft";
import RadioCard from "components/SusForm/Common/SusCheckRadioCard";
import { showToast } from "redux/toaster";
import { getSusCheck } from "helpers/utils/startup/susCheck";
import InsightsCard from "components/SusForm/Common/InsightsCard";

function Chapter4AOperation() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [data, setData] = useState({
    companyAge: details?.companyAge,
    ageOfCompany: details?.ageOfCompany,
  });
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data?.ageOfCompany === undefined) setDisabled(true);
    else setDisabled(false);
  }, [data]);

  const onNext = () => {
    if (data?.ageOfCompany === undefined) {
      setError({ ...error, ageOfCompany: "* select an age" });
    } else {
      dispatch(updateDraft({ value: data }));
      dispatch(nextStep());
    }
  };
  const doSusCheck = async (type, field, value, alternateField) => {
    try {
      let { val, message } = await getSusCheck(
        type,
        field,
        value,
        alternateField
      );
      let temp = { ...data };
      temp = { ...temp, ...val };
      setData(temp);
      dispatch(showToast({ message: message }));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DraftLayout
      heading="Years of Operation"
      subheading="Identify the company’s age"
      info="Information on how this is relevant"
      chapName="Viablity"
      onNext={onNext}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <main className="my-10 flex flex-col gap-10">
        <RadioCard
          list={[
            {
              label: "0-5 years",
              value: "0-5",
              flag: "green",
            },
            {
              label: "6-10 years",
              value: "6-10",
              flag: "white",
            },
            {
              label: "10+ years",
              value: "10+",
              flag: "red",
            },
          ]}
          data={data}
          field="companyAge"
          onCheck={(val) => {
            doSusCheck("startup", "companyAge", val, "ageOfCompany");
          }}
        />

        {data?.companyAge && (
          <InsightsCard
            data={data}
            field="companyAge"
            setData={(val) => {
              setData(val);
            }}
            placeholder=" eg., the founder's exact age"
            ideaText="Age is an indicator of a person's risk taking tendencies. Younger founders tend to be more open to risks"
          />
        )}
      </main>
    </DraftLayout>
  );
}

export default Chapter4AOperation;
