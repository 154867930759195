import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  details: {},
  step: 1,
  chapter: 1,
  chapters: {
    1: { name: "Cover", steps: [1, 2, 3] },
    2: { name: "The Founders", steps: [4, 5] },
    3: { name: "The Company", steps: [6, 7, 8, 9, 10] },
    4: { name: "The Viability", steps: [11, 12, 13, 14, 15] },
    5: { name: "Digital Footprint", steps: [16, 17, 18] },
  },
};

// Storing User details and Token
export const draft = createSlice({
  name: "draft",
  initialState,
  reducers: {
    updateDraftField: (state, action) => {
      let field = action.payload.field;
      let value = action.payload.value;
      state.details[field] = value;
    },
    updateDraft: (state, action) => {
      let value = action.payload.value;
      let temp = { ...state.details, ...value };
      state.details = temp;
    },

    nextStep: (state, action) => {
      let newStep = state.step + 1;
      switch (newStep) {
        case 4:
          state.chapter = 2;
          break;
        case 6:
          state.chapter = 3;
          break;
        case 11:
          state.chapter = 4;
          break;
        case 16:
          state.chapter = 5;
          break;

        default:
      }
      state.step = newStep;
    },
    prevStep: (state, action) => {
      let newStep = state.step > 1 ? state.step - 1 : 1;
      switch (newStep) {
        case 3:
          state.chapter = 1;
          break;
        case 5:
          state.chapter = 2;
          break;
        case 10:
          state.chapter = 3;
          break;
        case 15:
          state.chapter = 4;
          break;

        default:
      }
      state.step = newStep;
    },

    changeChapter: (state, action) => {
      let newChapter = state.chapter;

      switch (newChapter) {
        case 1:
          state.step = 1;
          break;
        case 2:
          state.step = 4;
          break;
        case 3:
          state.step = 6;
          break;
        case 4:
          state.step = 11;
          break;
        case 5:
          state.step = 16;
          break;

        default:
      }
      state.chapter = newChapter;
    },
    setForm: (state, action) => {
      state.details = action.payload.value;
      state.step = 1;
      state.chapter = 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateDraftField,
  updateDraft,
  nextStep,
  prevStep,
  changeChapter,
  setForm,
} = draft.actions;

export default draft.reducer;
