import { useState, useEffect } from "react";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft } from "redux/draft";
import RadioCard from "components/SusForm/Common/SusCheckRadioCard";
import { showToast } from "redux/toaster";
import { getSusCheck } from "helpers/utils/startup/susCheck";
import InsightsCard from "components/SusForm/Common/InsightsCard";

function Chapter4CEmp() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [data, setData] = useState({
    numOfEmployees: details?.numOfEmployees,
    numOfEmployeesType: details?.numOfEmployeesType,
  });
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data?.numOfEmployees === undefined) setDisabled(true);
    else setDisabled(false);
  }, [data]);

  const onNext = () => {
    if (data?.numOfEmployees === undefined) {
      setError({ ...error, workType: "* select a employees" });
    } else {
      dispatch(updateDraft({ value: data }));
      dispatch(nextStep());
    }
  };

  const doSusCheck = async (type, field, value, alternateField) => {
    try {
      let { val, message } = await getSusCheck(
        type,
        field,
        value,
        alternateField
      );
      let temp = { ...data };
      temp = { ...temp, ...val };
      setData(temp);
      dispatch(showToast({ message: message }));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DraftLayout
      heading="Employees"
      subheading="Identify the no. of people employed by the company"
      info="Information on how this is relevant"
      chapName="Viablity"
      onNext={onNext}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <main className="my-10 flex flex-col gap-10">
        <RadioCard
          list={[
            {
              label: "1-10 Employees",
              value: "1-10",
              flag: "green",
            },
            {
              label: "11-30 Employees",
              value: "11-30",
              flag: "white",
            },
            {
              label: "30+ Employees",
              value: "30+",
              flag: "red",
            },
          ]}
          data={data}
          field="numOfEmployees"
          onCheck={(val) => {
            doSusCheck("startup", "numOfEmployees", val);
          }}
        />

        {data?.numOfEmployees && (
          <InsightsCard
            data={data}
            field="numOfEmployees"
            setData={(val) => {
              setData(val);
            }}
            placeholder="eg., the current status of their startups, their names and the links to each of them"
            ideaText="Having little or no experience in building a company means that the founder could use some  help and guidance in building their startup"
          />
        )}
      </main>
    </DraftLayout>
  );
}

export default Chapter4CEmp;
