export const checkCompleteOrNot = (d) => {
  let chap3 = 0;
  let chap3Insight = 0;
  let chap3InsightCompleted = 0;

  if (d?.description) chap3++;
  if (d?.mission) chap3++;
  if (d?.vision) chap3++;
  if (d?.workLocation) {
    chap3++;
    chap3Insight++;
    if (d?.workLocation?.insights) chap3InsightCompleted++;
  }

  let chap4 = 0;
  let chap4Insight = 0;
  let chap4InsightCompleted = 0;

  if (d?.companyAge) {
    chap4++;
    chap4Insight++;
    if (d?.companyAge?.insights) chap4InsightCompleted++;
  }
  if (d?.fundingRounds) {
    chap4++;
    chap4Insight++;
    if (d?.fundingRounds?.insights) chap4InsightCompleted++;
  }
  if (d?.numOfEmployees) {
    chap4++;
    chap4Insight++;
    if (d?.numOfEmployees?.insights) chap4InsightCompleted++;
  }
  if (d?.revenue) {
    chap4++;
    chap4Insight++;
    if (d?.revenue?.insights) chap4InsightCompleted++;
  }

  let chap5 = 0;
  let chap5Insight = 0;
  let chap5InsightCompleted = 0;

  if (d?.website) chap5++;
  if (d?.socialsPresence?.instagram) chap5++;
  if (d?.socialsPresence?.linkedIn) chap5++;

  let chap = {
    1: {
      completed: d?.name && d?.image ? 100 : d?.name ? 50 : d?.image ? 50 : 0,
    },
    2: {
      completed:
        d?.founders?.length > 0
          ? (d?.founders?.length * 100) / d?.founders?.length
          : 0,
      insights: d?.founders?.length,
      completedInsights: d?.founders?.map((i) => i?.name)?.length,
    },
    3: {
      completed: (chap3 * 100) / 4,
      insights: chap3Insight,
      completedInsights: chap3InsightCompleted,
    },
    4: {
      completed: (chap4 * 100) / 4,
      insights: chap4Insight,
      completedInsights: chap4InsightCompleted,
    },
    5: {
      completed: (chap5 * 100) / 3,
      insights: chap5Insight,
      completedInsights: chap5InsightCompleted,
    },
  };

  let isDraft = !(
    d?.name &&
    d?.image &&
    chap[3]?.completed == 100 &&
    chap[4]?.completed == 100 &&
    chap[5]?.completed == 100
  );

  return { chap, isDraft };
};
