import { useState, useEffect } from "react";
import InsightsCard from "components/SusForm/Common/InsightsCard";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraft } from "redux/draft";
import RadioCard from "components/SusForm/Common/SusCheckRadioCard";
import { showToast } from "redux/toaster";
import { getSusCheck } from "helpers/utils/startup/susCheck";

function Chapter3AWorkType() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [data, setData] = useState({
    workLocation: details?.workLocation,
    workLocationType: details?.workLocationType,
  });
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data?.workLocationType === undefined) setDisabled(true);
    else setDisabled(false);
  }, [data]);

  const onNext = () => {
    if (data?.workLocation === undefined) {
      setError({ ...error, workType: "* select a work type" });
    } else {
      dispatch(updateDraft({ value: data }));
      dispatch(nextStep());
    }
  };

  const doSusCheck = async (type, field, value, alternateField) => {
    try {
      let { val, message } = await getSusCheck(
        type,
        field,
        value,
        alternateField
      );
      let temp = { ...data };
      temp = { ...temp, ...val };
      setData(temp);
      dispatch(showToast({ message: message }));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DraftLayout
      heading="Where Work Happens"
      subheading="Identify the company’s work location"
      info="Information on how this is relevant"
      chapName="Company"
      onNext={onNext}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <main className="my-10 flex flex-col gap-10">
        <RadioCard
          list={[
            {
              label: "Remote Work",
              value: "Remote Work",
              flag: "green",
            },
            {
              label: "Hybrid Work",
              value: "Hybrid Work",
              flag: "white",
            },
            {
              label: "On-Site Work",
              value: "On-Site Work",
              flag: "red",
            },
          ]}
          data={data}
          field="workLocation"
          onCheck={(val) => {
            doSusCheck("startup", "workLocation", val, "workLocationType");
          }}
        />

        {data?.workLocation && (
          <InsightsCard
            data={data}
            field="workLocation"
            setData={(val) => {
              setData(val);
            }}
            placeholder=" eg., the founder's exact age"
            ideaText="Age is an indicator of a person's risk taking tendencies. Younger founders tend to be more open to risks"
          />
        )}
      </main>
    </DraftLayout>
  );
}

export default Chapter3AWorkType;
