import { useState, useEffect } from "react";

import Covers from "../../../helpers/constants/drafts";
import DraftLayout from "../Common/draftLayout";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";

function Chapter1BCoverStartupSector() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);

  const [error, setError] = useState();
  const [selected, setSelected] = useState(details?.industry);

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (!selected) setDisabled(true);
    else setDisabled(false);
  }, [selected]);

  return (
    <DraftLayout
      heading="Identify the industry your startup operates in"
      onNext={() => {
        if (selected) {
          dispatch(updateDraftField({ field: "industry", value: selected }));
          dispatch(nextStep());
        } else setError("* select a sector");
      }}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={disabled}
    >
      <div>
        <p className="text-red-500 font-lato font-semibold text-xs my-2">
          {error}
        </p>
        <main className="grid grid-cols-2 h-full w-full mt-8 gap-4">
          {Covers.map((cover, index) => {
            return (
              <div
                onClick={() => {
                  setSelected(cover?.name);
                }}
                key={index}
                style={{ height: "120px" }}
                className={`${
                  selected === cover?.name
                    ? "border-2 border-primary-magenta-medium rounded-2xl shadow-md"
                    : ""
                } flex flex-col gap-4 justify-center items-center w-full min-h-30 h-30`}
              >
                <img
                  src={cover.img}
                  className="h-12 w-12 stroke-primary-magenta-medium"
                />
                <p
                  className={`font-lato font-semibold text-sm leading-[150%] tracking-[0.7px] text-center ${
                    selected === cover?.name
                      ? "text-primary-magenta-medium"
                      : "text-neutral-500"
                  }`}
                >
                  {cover.name}
                </p>
              </div>
            );
          })}
        </main>
      </div>
    </DraftLayout>
  );
}

export default Chapter1BCoverStartupSector;
