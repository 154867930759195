import { MagnifyingGlass, Layout, Cards } from "@phosphor-icons/react";

import StyleIcon from "@mui/icons-material/Style";

const mobileBottomNav = [
  {
    name: "Dashboard",
    path: "/dashboard",
    image: "/assets/svg/tabbar/dashboard.svg",
    selectedImage: "/assets/svg/tabbar/selectedDashboard.svg",
  },
  {
    name: "Stories",
    path: "/home/story/completed",
    image: "/assets/svg/tabbar/story.svg",
    selectedImage: "/assets/svg/tabbar/selectedStory.svg",
    countKey: "story",
  },
  {
    name: "Add",
    path: "/home/sus/0",
    image: "/assets/svg/tabbar/dashboard.svg",
    selectedImage: "/assets/svg/tabbar/dashboard.svg",
  },
  {
    name: "Draft",
    path: "/home/story/draft",
    image: "/assets/svg/tabbar/draft.svg",
    selectedImage: "/assets/svg/tabbar/selectedDraft.svg",
    countKey: "draft",
  },
  {
    name: "Bank",
    path: "/home/bank",
    image: "/assets/svg/tabbar/bank.svg",
    selectedImage: "/assets/svg/tabbar/selectedBank.svg",
  },
];
const laptopNav = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <Layout size={20} />,
  },
  {
    name: "Sus Forms",
    path: "/home/sus/0",
    icon: <StyleIcon weight="fill" size={20} />,
  },

  {
    name: "Research Bank",
    path: "/home/researchBank",
    icon: <MagnifyingGlass weight="fill" size={20} />,
  },
];

export { mobileBottomNav, laptopNav };
