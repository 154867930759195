import TopNav from "../Common/top_nav";
import Status from "../Common/status";
import { useDispatch, useSelector } from "react-redux";
import {
  changeChapter,
  nextStep,
  prevStep,
  setForm,
  updateDraftField,
} from "redux/draft";
import { createEntity, updateEntity } from "config/APIs/startup";
import { useHistory } from "react-router-dom";
import { checkCompleteOrNot } from "helpers/utils/startup";

function ChapterCongrats({ end }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const onSave = async () => {
    try {
      let { chap, isDraft } = checkCompleteOrNot(details);
      let body = {
        ...details,
        imageId: details?.image?.id,
        founders: details?.founder ? [{ name: details?.founder }] : null,
        isDraft: isDraft,
      };
      if (details?.id) {
        let data = await updateEntity(details?.id, body);
      } else {
        let res = await createEntity(body);
        dispatch(updateDraftField({ field: "id", value: res?.data?.data?.id }));
      }
    } catch (e) {
      console.log("Error: ", e);
    }

    if (end) {
      dispatch(setForm({ value: {} }));
      history.push(`/home/drafts/chapterflow/${details?.id}`);
    } else dispatch(nextStep());
  };
  return (
    <div className="sticky-thc flex flex-col bg-white justify-between h-full lg:h-auto">
      <div>
        <TopNav onBack={() => dispatch(prevStep())} />
        <div className="p-4">
          <Status />
        </div>
      </div>

      {/* Main Body --------- */}
      <main className="flex flex-col justify-center items-center before:bg-blue-300 p-4 w-full h-full overflow-y-scroll">
        <img
          className="h-40 w-40"
          src="/assets/svg/pages/drafts/congrats.svg"
        />
        <h1 className="mt-4 font-satoshi font-medium text-[28px] text-primary-magenta-medium">
          Congratulations!!!
        </h1>
        <h3 className="mt-2 text-center font-lato font-light">
          You earned the badge ‘_____’ for <br />
          completing the story cover!
        </h3>
        <button
          onClick={() => dispatch(changeChapter())}
          className="mt-8 font-lato font-semibold text-sm underline text-primary-magenta-medium"
        >
          Make Changes
        </button>
      </main>

      <div className="p-4 ">
        <button
          onClick={() => {
            onSave();
          }}
          className="w-full h-[60px] text-neutral-50 font-lato font-semibold text-center rounded-[20px] continue-button "
        >
          Next Chapter
        </button>
      </div>
    </div>
  );
}

export default ChapterCongrats;
