import { useState } from "react";

import DraftLayout from "../Common/draftLayout";
import TextAreaInput from "../Common/textarea_input";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, prevStep, updateDraftField } from "redux/draft";

function Chapter3AMission() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.draft?.details);
  const [mission, setMission] = useState(details?.mission);

  return (
    <DraftLayout
      heading="The Mission"
      subheading="State the company’s Mission Statement"
      info="Information on where to find this"
      chapName="Company"
      onNext={() => {
        dispatch(updateDraftField({ field: "mission", value: mission }));
        dispatch(nextStep());
      }}
      onPrevious={() => {
        dispatch(prevStep());
      }}
      bottomDisabled={false}
    >
      <main className="my-10 flex flex-col gap-10">
        <TextAreaInput
          value={mission}
          label="Mission Statement"
          placeholder="Company mission statement comes here"
          onChange={(e) => setMission(e.target.value)}
        />
      </main>
    </DraftLayout>
  );
}

export default Chapter3AMission;
